<template>
	<artistProfileLayout>
		<div class="page_label_profile">
			<div class="head_profile">
				<div v-if="headBackground" class="profile_head_background" v-bind:style="{'background-image': 'url('+(headBackground)+')'}"></div>
				<div v-else class="profile_head_background default"></div>

				<v-container>
					<div class="record_label_data">
						<recordLabelProfileAvatar :labelID="id" :isOwner="isOwnerLabel"></recordLabelProfileAvatar>

						<div class="label_info">
							<h2 class="label_name">
<!--								<template v-if="editLabel && tabs === 2">-->
<!--									<input type="text" v-model.trim="changeData.title">-->
<!--								</template>-->
								<template>
									{{ RECORD_LABEL.title }}
								</template>
							</h2>
							<div class="follows" v-if="RECORD_LABEL">
								<span class="count">{{ followersCount }}</span>
								<div>Followers</div>
							</div>
						</div>
					</div>
				</v-container>
			</div><!-- end head_profile -->


			<div class="wrap_profile_music_events">
				<div class="head_profile_music_events">
					<v-container>
						<div class="head_content">
							<v-tabs background-color="transparent" color="#fff" v-model="tabs">
								<v-tab v-for="tab in tabsSection" :key="tab.index">{{ tab.name }}</v-tab>
							</v-tabs>
							<div class="actions">
								<template v-if="RECORD_LABEL && RECORD_LABEL.owner && RECORD_LABEL.owner.id === userID">
									<button type="button" class="edit_profile" v-if="!editLabel" @click="editLabelProfile" v-ripple>
										Edit profile
									</button>
								</template>
								<template v-else>
									<template v-if="actionButtonsLoader === true  || followBtnLoader || messageBtnLoader || supportBtnLoader">
										<v-skeleton-loader v-if="followBtnLoader || !this.AUTH_DATA.accessToken.length" max-width="300" height="34" type="button"></v-skeleton-loader>
										<v-skeleton-loader v-if="messageBtnLoader || !this.AUTH_DATA.accessToken.length" max-width="300" height="34" type="button"></v-skeleton-loader>
										<v-skeleton-loader v-if="supportBtnLoader || !this.AUTH_DATA.accessToken.length" max-width="300" height="34" type="button"></v-skeleton-loader>
									</template>
									<template v-if="actionButtonsLoader === false && this.AUTH_DATA.accessToken">
										<button type="button" class="follow_btn" v-if="this.isFollow === false && !followBtnLoader" @click="follow(RECORD_LABEL.id)">
											<span class="icon"></span>
											Follow
										</button>
										<button type="button" class="follow_btn following" v-else-if="!followBtnLoader" @click="unfollow(RECORD_LABEL.id)"
										        @touchend="hoverFollowingButton = false"
										        @mouseover="hoverFollowingButton = true"
										        @mouseleave="hoverFollowingButton = false">
											<span class="icon"></span>
											<template v-if="hoverFollowingButton === true">Unfollow</template>
											<template v-else>Following</template>
										</button>
										<button type="button" class="message_btn" @click="showComingSoonDialog = true">
											<span class="icon"></span>
											Message
										</button>
                    <button v-if="!copiedArtistLink" type="button" class="share_btn" @click="copyArtistLink">
                      <span class="icon"></span>
                      Share
                    </button>
                    <button v-if="copiedArtistLink" type="button" class="share_btn">
                      Copied!
                    </button>
									</template>

                  <template v-if="actionButtonsLoader === false && !this.AUTH_DATA.accessToken">
                    <button type="button" class="follow_btn" @click="showNeedLoginDialog = true">
                      <span class="icon"></span>
                      Follow
                    </button>
                    <button type="button" class="message_btn" @click="showNeedLoginDialog = true">
                      <span class="icon"></span>
                      Message
                    </button>
                    <button v-if="!copiedArtistLink" type="button" class="share_btn" @click="copyArtistLink">
                      <span class="icon"></span>
                      Share
                    </button>
                    <button v-if="copiedArtistLink" type="button" class="share_btn">
                      Copied!
                    </button>
                  </template>
								</template>

								<!-- more button -->
								<v-menu
									transition="slide-y-transition"
									:close-on-content-click="true"
									offset-y
									nudge-left="21"
									nudge-bottom="10"
									origin="top center"
									content-class="menu-pointer charcoal">
									<template v-slot:activator="{ on, attrs }">
										<v-btn icon color="#7481A6" class="more" v-bind="attrs" v-on="on">
											<span class="material-icons">more_vert</span>
										</v-btn>
									</template>
									<v-list>
										<!-- popup share link -->
<!--										<v-list-item>-->
<!--											<v-dialog transition="dialog-bottom-transition" max-width="600">-->
<!--												<template v-slot:activator="{ on, attrs }">-->
<!--													<button v-ripple type="button" v-bind="attrs" v-on="on">Share</button>-->
<!--												</template>-->
<!--												<template v-slot:default="dialog">-->
<!--													<div class="content_popup_share">-->
<!--														<div class="head_popup">Share</div>-->
<!--														<div class="input_box">-->
<!--															<input type="text" id="input-share-link" v-model="shareLink">-->
<!--															<button v-ripple type="button" @click="copyArtistLink">Copy</button>-->
<!--														</div>-->
<!--														<button v-ripple type="button" @click="dialog.value = false" class="close">Close</button>-->
<!--													</div>-->
<!--												</template>-->
<!--											</v-dialog>-->
<!--										</v-list-item>-->
										<!-- Report -->
										<v-list-item v-ripple>
											<button type="button" class="report">Report</button>
										</v-list-item>
									</v-list>
								</v-menu>
							</div>
						</div>
					</v-container>
				</div>
				<div class="content_profile_music_events">
					<v-tabs-items touchless v-model="tabs">
						<!-- Music Tab -->
						<v-tab-item :transition="false">
							<div class="music_section artist_releases">

								<div class="label_releases">
									<div class="container">
										<div class="head_profile_content">
											<h3 class="_head_name">Releases</h3>
										</div>

										<div v-if="recordLabelReleasesLoader || loader" class="wrap_music skeleton-loader">
											<albumGridSkeletonLoader v-for="index in 6" :key="index" :parent="'default_grid_albums'"></albumGridSkeletonLoader>
										</div>
										<div class="wrap_music">
											<template v-if="!recordLabelReleasesLoader && recordLabelReleases && recordLabelReleases.length">
												<albumGrid v-for="album in recordLabelReleases"
												           :key="album.id"
												           :album="album"
														   :playZone="recordLabelReleases"
												></albumGrid>
											</template>
										</div>
										<template v-if="!loader && !recordLabelReleasesLoader && !recordLabelReleases.length">
											<nothingHereYet></nothingHereYet>
										</template>
									</div>
								</div>

							</div>
						</v-tab-item>

						<!-- Events Tab -->
						<v-tab-item :transition="false">
							<div class="events_section">
								<div class="uploaded_events">
									<v-container>
										<div class="head_profile_content">
                                            <span class="_head_name">
                                                Coming up
                                            </span>
										</div>
										<nothingHereYet></nothingHereYet>

									</v-container>
								</div>
							</div>
						</v-tab-item>

						<!-- About Tab -->
						<v-tab-item :transition="false">
							<div class="artist_about">
								<v-container class="v_container_bio">
									<div class="head_profile_content">
                                    <span class="_head_name">
                                       Bio
                                    </span>
									</div>
									<div class="wrap_about_section">
										<template v-if="editLabel">
											<TextareaAutosize v-model="changeData.description"></TextareaAutosize>
											<div class="wrap_action_btn">
												<button type="button" class="save" v-ripple @click="saveRecordLabel" :class="{loading: changeRecordLabelLoader}">
													<v-progress-circular :width="2" indeterminate></v-progress-circular>
													Save
												</button>
												<button type="button" class="close" v-ripple @click="cancel">Cancel</button>
											</div>
										</template>
										<template v-else>
											<template v-if="RECORD_LABEL.description">
												<div v-html="formattedBio"></div>
											</template>
											<template v-else>
												<nothingHereYet></nothingHereYet>
<!--												<div class="nothing_here_yet">-->
<!--													<div class="container">-->
<!--														Nothing here yet-->
<!--													</div>-->
<!--												</div>-->
											</template>
										</template>
									</div>
								</v-container>

							</div>
						</v-tab-item>
					</v-tabs-items>
				</div>
			</div>

			<comingSoonDialog v-if="showComingSoonDialog" v-model="showComingSoonDialog"></comingSoonDialog>
			<dialogNeedLogin v-if="showNeedLoginDialog" v-model="showNeedLoginDialog"></dialogNeedLogin>
			<errorDialog v-if="errorDialog" v-model="errorDialog"></errorDialog>
		</div>
	</artistProfileLayout>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import artistProfileLayout from "@/layouts/profiles/artistProfileLayout";
import recordLabelProfileAvatar from "@/components/profile/recordLabelProfileAvatar";
import checkAccessToken from "@/mixins/checkAccessToken";
import isMobile from "@/mixins/isMobile";
import Defer from "@/utils/defer";
import albumGridSkeletonLoader from "@/components/small/skeleton-loaders/albumGridSkeletonLoader.vue";
import albumGrid from "@/components/musicReleases/albumGrid.vue";

const comingSoonDialog = () => import('@/components/dialogs/comingSoonDialog');
const dialogNeedLogin = () => import('@/components/dialogs/needLogin');
const nothingHereYet = () => import('@/components/small/nothingHereYet');
const errorDialog = () => import('@/components/dialogs/error');

export default {
	name: 'label-profile',
	props: ['id', 'name'],
	components: {
		recordLabelProfileAvatar,
		nothingHereYet, errorDialog,
		albumGrid, albumGridSkeletonLoader,
		artistProfileLayout,
		comingSoonDialog,
		dialogNeedLogin
	},
	mixins: [checkAccessToken, isMobile, Defer()],
	data() {
		return {
			apiUrl: process.env.VUE_APP_API_URL,
			headBackground: '',
			loader: false,
			editLabel: false,
			changeRecordLabelLoader: false,

			changeData: {
				title: null,
				description: null,
			},

			tabs: null,
			tabsSection: [
				{index: 0, name: 'Music'},
				{index: 1, name: 'Events'},
				{index: 2, name: 'About'}
			],

			actionButtonsLoader: true,
			hoverFollowingButton: false,
			isFollow: false,
			followersCount: null,
			followBtnLoader: false,
			messageBtnLoader: false,
			supportBtnLoader: false,

			showComingSoonDialog: false,
			showNeedLoginDialog: false,
			errorDialog: false,

			shareLink: window.location.href,
			isOwnerLabel: false,
      copiedArtistLink: false,
		}
	},
	watch: {
		RECORD_LABEL: {
			handler() {
				this.getBackground();
			},
			deep: true
		},
		tabs() {
			if (this.tabs !== 2) {
				this.editLabel = false;
			}
		}
	},
	computed: {
		...mapGetters({
			userID: 'userID',
			AUTH_DATA: 'AUTH_DATA',
			RECORD_LABEL: 'RECORD_LABEL',
			RECORD_LABEL_FOLLOWERS: 'RECORD_LABEL_FOLLOWERS',
			recordLabelReleases: 'RECORD_LABEL_RELEASES',
			recordLabelReleasesLoader: 'RECORD_LABEL_RELEASES_LOADER',
			hasFollowedRecordLabel: 'HAS_FOLLOWED_RECORD_LABEL',
		}),
		formattedBio() {
			return this.RECORD_LABEL.description.replace(/\n/g, '<br>');
		}
	},
	created() {
		this.getRecordLabelData();
	},
	mounted() {
		let edit = this.$router.history.current.params.edit;
		if (edit === true) {
			this.editLabelProfile();
		}
	},
	methods: {
		...mapActions({
			getRecordLabel: 'GET_RECORD_LABEL',
			getRecordLabelFollowers: 'GET_RECORD_LABEL_FOLLOWERS',
			followRecordLabel: 'FOLLOW_RECORD_LABEL',
			unfollowRecordLabel: 'UNFOLLOW_RECORD_LABEL',
			changeRecordLabel: 'CHANGE_RECORD_LABEL',
			getRecordLabelReleases: 'GET_RECORD_LABEL_RELEASES',
			getHasFollowedRecordLabel: 'GET_HAS_FOLLOWED_RECORD_LABEL'
		}),
		getBackground() {
			if (this.RECORD_LABEL.logo) {
				this.headBackground = `${this.apiUrl + this.RECORD_LABEL.logo.contentUrl}`;
			}
		},
		getDataLabel() {
			let recordLabel = this.id || this.name;
			this.getRecordLabel(recordLabel)
				.then(() => {
					this.changeData.title = this.RECORD_LABEL.title;
					this.changeData.description = this.RECORD_LABEL.description;
				})
				.catch(err => console.error(err));
		},
		getRecordLabelData() {
			this.loader = true;
			this.actionButtonsLoader = true;
			// const labelNameWithSpaces = this.name.replace(/-/g, ' ');

			let recordLabel = this.id || this.name;
			this.getRecordLabel(recordLabel)
				.then(() => {
          if (!this.RECORD_LABEL.id) {
            this.$router.push({name: "404"});
          }
          this.changeData.title = this.RECORD_LABEL.title;
          this.changeData.description = this.RECORD_LABEL.description;

          this.isOwnerLabel = this.RECORD_LABEL && this.RECORD_LABEL.owner !== null && this.RECORD_LABEL.owner.id === this.userID;
          this.followersCount = this.RECORD_LABEL.followersCount;
          if (this.AUTH_DATA.accessToken) {
            this.getHasFollowedRecordLabel(this.RECORD_LABEL.id)
                .then(() => {
                  this.isFollow = this.hasFollowedRecordLabel;
                })
                .catch(err => {
                  console.error(`getHasFollowedRecordLabel, ${err}`)
                })
                .finally(() => this.actionButtonsLoader = false);
          } else {
            this.actionButtonsLoader = false;
          }

					this.getRecordLabelReleases(this.RECORD_LABEL.id)
						.catch(err => console.error(`get record-label releases, ${err}`));
				})
				.catch(err => {
					console.error(`get record-label, ${err}`);
					this.$router.push({name: "404"});
				})
				.finally(() => this.loader = false);
		},
		editLabelProfile() {
			this.editLabel = true;
			this.tabs = 2; // aboutSection
		},
		cancel() {
			this.editLabel = false;
			this.tabs = 0; // musicSection
			this.changeData.title = this.RECORD_LABEL.title;
			this.changeData.description = this.RECORD_LABEL.description;
		},

		follow(labelID) {
			if (this.AUTH_DATA.accessToken || !this.checkAccessToken()) {
				this.followBtnLoader = true;

				this.followRecordLabel(labelID)
					.then(result => {
						if (result === true) {
							this.followBtnLoader = false;
							this.isFollow = true;
							this.followersCount = this.RECORD_LABEL.followersCount += 1;
						} else {
							this.errorDialog = true;
						}
					})
					.catch(err => {
						this.errorDialog = true;
						console.error(`follow record-label, ${err}`)
					})
					.finally(() => this.followBtnLoader = false);
			} else {
				this.showNeedLoginDialog = true;
			}
		},
		unfollow(labelID) {
			if (this.AUTH_DATA.accessToken || !this.checkAccessToken()) {
				this.followBtnLoader = true;

				this.unfollowRecordLabel(labelID)
					.then(result => {
						if (result === true) {
							this.followBtnLoader = false;
							this.isFollow = false;
							if (this.RECORD_LABEL.followersCount >= 0) {
								this.followersCount = this.RECORD_LABEL.followersCount -= 1;
							}
						} else {
							this.errorDialog = true;
						}
					})
					.catch(err => {
						this.errorDialog = true;
						console.error(`unfollow record-label, ${err}`)
					})
					.finally(() => this.followBtnLoader = false);

			} else {
				this.showNeedLoginDialog = true;
			}
		},
		copyArtistLink() {
			navigator.clipboard.writeText(this.shareLink);

      this.copiedArtistLink = true;

      setTimeout(() => {
        this.copiedArtistLink = false;
      }, 2000);
		},
		saveRecordLabel() {
			this.changeRecordLabelLoader = true;
			let params = {
				id: this.RECORD_LABEL.id,
				title: this.changeData.title,
				description: this.changeData.description,
				// address: null
			};
			this.changeRecordLabel(params)
				.then(() => {
					this.getDataLabel();
					this.editLabel = false;
				})
				.catch(err => {
					this.errorDialog = true;
					console.error(`saveRecordLabel, ${err}`)
				})
				.finally(() => this.changeRecordLabelLoader = false);
		},
	}

}
</script>

<style lang="scss">
footer {
  margin-top: 200px !important;
}
</style>
